<template>
  <v-card flat class="data-box pb-4 pl-3 pr-3 pl-lg-8 pr-lg-8" height="100%">
<!--    <div class="data-box__body " v-if="body">-->
      <card-header v-if="header || headerText">
        <div class="card-header__title">{{ headerText }}</div>
        <slot name="headerRightButton"></slot>
      </card-header>

      <sub-header v-if="subHeaderContent" :class="subHeaderClasses">
        <div class="big-text">{{ subHeaderContent.value }}</div>
        <trend :value="subHeaderContent.statsValue"
               :text="subHeaderContent.statsText"></trend>
      </sub-header>

      <card-column-line v-if="bodyItemsContent" :items="bodyItemsContent"/>

      <slot name="default"></slot>

      <card-footer v-if="$slots.footer">
        <slot name="footer"></slot>
      </card-footer>
<!--    </div>-->
  </v-card>
</template>

<script>
import CardHeader from "@/components/ui/card-elements/CardHeader";
import CardFooter from "@/components/ui/card-elements/CardFooter";
import Trend from "@/components/ui/card-elements/Trend";
const SubHeader = () => import(/* webpackChunkName: "sub-header" */  "@/components/ui/card-elements/CardSubheader");
const CardColumnLine  = () => import(/* webpackChunkName: "card-column-line" */ "@/components/ui/card-elements/CardColumnLine");

export default {
  name: "Card",
  components: {
    Trend,
    CardFooter,
    CardHeader,
    SubHeader,
    CardColumnLine
  },
  props: {
    header: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: null,
    },
    subHeaderClasses: {
      type: Array,
      default: null,
    },
    subHeaderContent: {
      type: Object,
      default: null,
    },

    body: {
      type: Boolean,
      default: true
    },
    bodyType: {
      type: String,
      default: 'items',
    },
    bodyBgColor: {
      type: String,
      default: '#FBFBFB',
    },
    bodyItemsContent: {
      type: Array,
      default: null,
    },
    footer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({})
}
</script>

<style lang="scss">
.v-card.v-sheet.data-box {
  border-radius: 6px;
}

.data-box {
  display: flex !important;
  flex-direction: column !important;

  >div:first-child {
    margin-top: 18px;
  }
  >div {
    margin-top: 24px;
  }

  &__footer {
    width: 100%;
    font-size: 14px;
    line-height: 19px;
  }
}

</style>
