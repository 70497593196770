import httpClient from "@/api/http-client";

const END_POINT = '/authorizations';

const apiSetUserToken = async userData => {
  const url = process.env.VUE_APP_EMBEDDED == 1 ? '/internal': '';
  try {
    const {data} = await httpClient.post(END_POINT + url, userData, {
      skipAuth: true,
    });
    return data;
  } catch (error) {
    console.error(`Set user jwt token failed with error: ${error.message}`);
    return error.response;
  }
};

const apiRefreshToken = async () => {
  const tokenName = process.env.VUE_APP_EMBEDDED == 1 ? 'syqor_token' : 'syqor_erp_fe_token';
  const token = JSON.parse(localStorage.getItem(tokenName)).token;
  try {
    const {data} = await httpClient.put(`${END_POINT}/current`, null, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      skipAuth: true
    });
    return data;
  } catch (error) {
    console.error(`Refresh user jwt token failed with error: ${error.message}`);
    return error.response;
  }
};

const apiDeleteCurrentToken = async () => {
  try {
    await httpClient.delete(`${END_POINT}/current`);
  } catch (error) {
    console.error(`Deleting current jwt token failed with error: ${error.message}`);
  }
}

const recoverEmailRequest = async email => {
  try {
     const {data} = await httpClient.post(`${END_POINT}/recover-request`, email, {
       skipAuth: true
     });
    return data;
  } catch (error) {
    console.error(`Recover password request failed with error: ${error.message}`);
    return error.response;
  }
}

const recoverPasswordRequest = async passwordsData => {
  try {
    const {data} = await httpClient.post(`${END_POINT}/recover-set`, passwordsData, {
      skipAuth: true
    });
    return data;
  } catch (error) {
    console.error(`Recover password request failed with error: ${error.message}`);
    return error.response;
  }
}

export {
  apiSetUserToken,
  apiRefreshToken,
  apiDeleteCurrentToken,
  recoverEmailRequest,
  recoverPasswordRequest,
};
