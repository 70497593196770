import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
//import qs from 'qs';
import queryString from 'query-string';
import '@/utilities/polyfills';
import '@/plugins/date.js';
import '@/plugins/card';
import '@/plugins/filterSelect';
//import createService from '@/api/fake-backend';
//const moment = import(/* webpackChunkName: "moment" */ 'moment');
//createService();
import '@/assets/scss/app.scss';
//import '@/assets/scss/app.module.scss';
//import '@/api/dashboard/operational/index'

Vue.config.productionTip = false

Vue.prototype.$print = function (val, key) {
  console.log('Name: '+ key, val)
}
Vue.prototype.moment = moment;
Vue.prototype.qs = queryString;
console.log('vuetify', vuetify);
let app = new Vue({
  render: h => h(App),
  router,
  vuetify,
  store
}).$mount('#app')

console.log('app.vuetify', app.$vuetify)
