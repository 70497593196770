import Vue from 'vue';
//const moment = import(/* webpackChunkName: "moment" */ 'moment');
//console.log('moment', moment);
function minusDays(countDayes = 1, date = new Date()) {
    const date2 = new Date();
    date2.setTime(date.getTime() - 3600 * 1000 * 24 * countDayes);
    return date2;
}

Vue.prototype.$format = "MM/DD/YYYY";
Vue.prototype.$formatTabel = function formatTabel(unix) {
    return moment.unix(unix).format("MMM DD.YYYY")
};
Vue.prototype.$shortcuts = [
    {
        text: 'Last 7 days',
        onClick: ()=> ([minusDays(7),  new Date()])
    },
    {
        text: 'Last 14 days',
        onClick: ()=> ([minusDays(14),  new Date()])
    },
    {
        text: 'Last Month',
        onClick: ()=> ([minusDays(31),  new Date()])
    },
    {
        text: 'Last Quarter',
        onClick: ()=> ([minusDays(31*3),  new Date()])
    },
    {
        text: 'Last Year',
        onClick: ()=> ([minusDays(31*12),  new Date()])
    },
];
