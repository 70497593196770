import router from "@/router";
import jwt_decode from "jwt-decode";
import {apiSetUserToken, apiRefreshToken, apiDeleteCurrentToken} from '@/api/authorizations.api';

const tokenName = process.env.VUE_APP_EMBEDDED == 1 ? 'syqor_token' : 'syqor_erp_fe_token';
const getUnixTimestampDate = date => moment(date).valueOf();

export default {
  state: {
    tokenData: JSON.parse(localStorage.getItem(tokenName)) ?? null,
  },

  getters: {
    tokenData: state => state.tokenData,
    isLoggedIn: state => state.tokenData?.refresh_expired_at && Date.now() < getUnixTimestampDate(state.tokenData?.refresh_expired_at),
    isTokenUrlValid: state => jwt_decode(state.tokenData?.token).iss.includes(process.env.VUE_APP_BASE_API_URL),
    isAccessTokenValid: state => now => now < getUnixTimestampDate(state.tokenData?.expired_at),
    isRefreshTokenValid: state => now => now < getUnixTimestampDate(state.tokenData?.refresh_expired_at),
  },

  mutations: {
    seTokenData(state, tokenData) {
      state.tokenData = {...tokenData};
    },

    logout(state) {
      state.tokenData = null;
      localStorage.removeItem(tokenName);
      if (router.currentRoute.name !== 'login' && process.env.VUE_APP_EMBEDDED == 0) {
        router.push({name: 'login'});
      }
    }
  },

  actions: {
    async retrieveToken({commit, dispatch}, credentials) {
      const {data} = await apiSetUserToken(credentials);

      if ('status_code' in data) {
        dispatch('logout');
      } else {
        commit('seTokenData', data);
        localStorage.setItem(tokenName, JSON.stringify(data));
      }

      return data;
    },

    async refreshToken({commit, dispatch}) {
      const {data} = await apiRefreshToken();

      if ('status_code' in data) {
        dispatch('logout');
      } else {
        commit('seTokenData', data);
        localStorage.setItem(tokenName, JSON.stringify(data));
      }

      return data.token;
    },

    async logout({commit, getters}) {
      if (getters.isTokenUrlValid) {
        await apiDeleteCurrentToken();
      }
      commit('logout');
    },

    async login({dispatch}, credentials) {
      const data = await dispatch('retrieveToken', credentials);
      if ('status_code' in data) {
        return data;
      } else {
        router.push({name: 'dashboard'});
        return data.token;
      }
    },
  }

};
