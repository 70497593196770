import Vue from 'vue';
import Vuex from 'vuex';
import themesState from './modules/thems.store';
import sidebarState from './modules/sidebar.store';
import authorizations from './modules/authorizations.store';
import preloader from './modules/preloader.store';
import query from './modules/params.store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        themesState,
        sidebar: sidebarState,
        auth: authorizations,
        preloader,
        query
    },
    state: () => ({
        test: 1,
        dialogTest: false,
    })
});

