//if (localStorage.getItem('themeDark') === null)
    localStorage.setItem('themeDark', 'true');

const defaultState = JSON.parse(localStorage.getItem('themeDark'));

export default {
    state: ()=>({
        dark: defaultState
    }),
    getters: {
      isDark(state) {
          return state.dark;
      }
    },
    mutations: {
        SET_THEM_DARK(state, playload) {
            state.dark = playload;
        }
    },
    actions: {
        toggleTheme(context) {
            const dark = !context.state.dark;
            context.commit('SET_THEM_DARK', dark);
            localStorage.setItem('themeDark', dark);
        }
    }
}