<template>
  <div class="mt-auto card__footer" :class="[paddings]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardFooter",
  props: {
    paddings: {
      type: String,
      default: 'pt-5'
    }
  }
}
</script>

<style scoped>

</style>