import Vue from 'vue';
import FilterSelect from '@/components/ui/FilterSelect';

Vue.prototype.$dateOffset = [
    { value: 0, text: "Current day"},
    { value: 7, text: "Weekly" },
    { value: 30, text: "Monthly" },
    { value: 365, text: "Yearly" }
]

Vue.component('FilterSelect', FilterSelect);
