<template>
  <v-app>

    <component :is="layout">
      <router-view></router-view>
    </component>

  </v-app>
</template>

<script>
import Default from "@/layouts/Default";
import Login from "@/layouts/Login";

export default {
  name: 'App',
  components: {
    Default,
    Login,
  },
  computed: {
    layout() {
      return this.$route.meta.layout ?? 'default';
    },
  },
};
</script>

<style lang="scss">
.v-application {
  font-family: "Nunito", sans-serif !important;
  line-height: 1.5;
}

.big-text {
  font-size: 42px;
  line-height: 72px;
  text-transform: uppercase;
  margin-bottom: 4px;
}
//.data-box  .big-text {
 // max-width: 300px;
 // word-break: break-all;
//}


.v-application .caption {
  font-size: 1rem !important;
  font-weight: 800;
  //letter-spacing: 0.0333333333em !important;
  line-height: 1.375rem; // 1.25rem;
  font-family: "Nunito", sans-serif !important;;
  font-style: normal;
  text-align: center;
}

.container {
  background: linear-gradient(0deg, #F2F4F7 0%, rgba(228, 233, 241, 0.7) 133.19%);;
}

.v-application .font-weight-semi-bold {
  font-weight: 600 !important;
}

.v-application .filter-row {
  padding-top: 12px !important;
  padding-bottom: 4px !important;

  > div {
    padding-bottom: 0 !important;
  }
}

</style>
